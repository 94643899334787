module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"sCQcc9xAoRBnSGrC6t0cBNpGFufPmbyl","devKey":"sCQcc9xAoRBnSGrC6t0cBNpGFufPmbyl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"62cc267637004d1181599185","options":{"bootstrap":"localStorage"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
